
import {addDoc} from "firebase/firestore";
import {expensesRef} from "@/firebasequeries";
import {group} from "@/groups";



export const addExpense = (expence) => {
    const data = {
        date: new Date(expence.date),
        sum: Number(expence.sum),
    }
    if (expence.group_id){
        data.group = group(expence.group_id)
    }
    return addDoc(expensesRef, data);
}