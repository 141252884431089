import { createApp } from 'vue'
import { VueFire, VueFireAuth } from 'vuefire'
import { firebaseApp } from './firebasequeries'

import App from './App.vue'
import router from './router'

const app = createApp(App).use(router)
app
    .use(VueFire, {
        // imported above but could also just be created here
        firebaseApp,
        modules: [
            // we will see other modules later on
            VueFireAuth(),
        ],
    })


app.mount('#app')
