<template>
  <select @change="change" @select="change" :value="modelValue" style="min-width: 125px" v-if="groups">
    <option v-for="group in groups" :key="group.id" :value="group.id" >{{group.title}}</option>
  </select>
</template>

<script setup>
import {defineProps, defineEmits} from "vue";
import {useCollection} from "vuefire";
import {groupsQuery} from "@/groups";


defineProps({
  modelValue: String,
})
const emit = defineEmits(['change', 'update:modelValue'])

const groups = useCollection(groupsQuery, {ssrKey:'-'})
const change = (e) => {
  emit('update:modelValue', e.target.value)
  emit('change', e.target.value)
}
</script>

