import { createRouter, createWebHistory } from 'vue-router'
import ExpensesPage from '../pages/ExpensesPage'

const routes = [
  {
    path: '/',
    name: 'expenses',
    component: ExpensesPage
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import(/* webpackChunkName: "about" */ '../pages/GroupsPage')
  },
  {
    path: '/stat',
    name: 'stat',
    component: () => import(/* webpackChunkName: "about" */ '../pages/StatPage')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
