<template>
  <div class="colorbox" :style="css">&nbsp;</div>
</template>

<script setup>
import {computed, defineProps} from "vue";
const props = defineProps({
  color: String
})
const css = computed(() => {
  return {
    background: props.color,
  }})
</script>

<style scoped>
.colorbox{
  border: 1px solid #dddddd;
  width: 20px;
}
</style>