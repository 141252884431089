<template>
  <table class="table">
    <thead>
    <tr>
      <th style="width: 20%">Date</th>
      <th style="width: 40%;">Category</th>
      <th>Amount</th>
      <th style="width: 25px;padding: 0"></th>
    </tr>
    </thead>
    <tbody>
    <ExpenseRow v-for="expense in expenses" :expense="expense" :key="expense.id" />
    </tbody>
  </table>
</template>

<script setup>
import ExpenseRow from "@/components/ExpenseRow";
import {useCollection} from "vuefire";
import {expensesRef} from "@/firebasequeries";
import {limit, orderBy, query} from "firebase/firestore";
import {reactive} from "vue";
/*import { getDocs } from "firebase/firestore";*/
/*import {ref} from "vue";*/


const pagination = reactive({
  limit: 50,
  offset: 0,
  total: 0
})

const q = query(expensesRef, orderBy("date", "desc"), limit(pagination.limit));

const expenses = useCollection(q, {ssrKey:'-'})
//const expenses = useCollection(queryExpenses, {ssrKey:'-'})

/*const ex = ref([])*/
/* getDocs(queryExpenses).then((data) => ex.value = data)*/

</script>

