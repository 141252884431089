<template>
  <tr>
    <td>{{(expense.date.toDate().toLocaleDateString())}}</td>
    <td>
        <div v-if="editGroup" class="flex">
          <GroupSelect v-model="groupId" @change="changeExpenseGroup" class="input"/>
          <IconRemove @click="editGroup=false"/>
        </div>
        <div class="flex" v-else>
          <ColorBox :color="expense.group.color"/>
          <span>{{ expense.group.title }}</span>
          <IconEdit @click="editGroup=!editGroup"/>
        </div>


    </td>
    <td>
      <div v-show="!editSum" class="flex">
        <span @click="editSum=true">{{ unsavedSum }}</span>
        <IconSplit @click="splitRow = true"/>

      </div>
      <div v-if="editSum" class="flex">
        <input type="text" v-model="unsavedSum" class="input sum" @keyup.enter="changeSum"/>
        <IconCheck @click="changeSum" :width="14"/>
      </div>
    </td>
    <td>
      <IconRemove @click="deleteExpense" color="#910000" class="remove"/>
    </td>
  </tr>
  <tr v-if="splitRow" class="new">
    <td>{{(expense.date.toDate().toLocaleDateString())}}</td>
    <td>
      <GroupSelect v-model="newExpense.group_id" class="input" />
    </td>
    <td>
      <div class="flex">
        <input type="text" v-model="newExpense.sum" class="input sum" autofocus @keyup.enter="addSplit"/>
        <IconPlus @click="addSplit"/>
      </div>
    </td>
    <td>
      <div class="remove">
        <IconRemove @click="splitRow=false" color="#910000"/>
      </div>
    </td>
  </tr>
</template>

<script setup>
import {defineProps, ref, watch, reactive} from "vue";
import {useFirestore} from "vuefire";
import {doc, deleteDoc, updateDoc} from 'firebase/firestore'
import GroupSelect from "@/components/GroupSelect";
import IconEdit from "@/components/icons/IconEdit";
import IconRemove from "@/components/icons/IconRemove";
import IconSplit from "@/components/icons/IconSplit";
import IconPlus from "@/components/icons/IconPlus";
import IconCheck from "@/components/icons/IconCheck";
import {addExpense} from "@/expenses";
import ColorBox from "@/components/ColorBox";



const props = defineProps({
  expense: Object
})


const editGroup = ref(false)
const editSum = ref(false)
const splitRow = ref(false)
const groupId = ref(props.expense.group.id)

const newExpense = reactive({group_id: 'ncMJAqOycvZO9VZBTFfi', sum: '', date: props.expense.date.toDate()})
const unsavedSum = ref(props.expense.sum)


const db = useFirestore()


const deleteExpense = () => {
  if (confirm('Are you sure?')){
    const dbObject = () => doc(db, "expenses", props.expense.id);
    deleteDoc(dbObject());
  }
}
const changeExpenseGroup = (group_id) => {
  editGroup.value = false
  update({group: doc(db, `groups/${group_id}`)})
}

const changeSum = () => {
  const sum = Number(unsavedSum.value);
  editSum.value = false
  update({sum: sum})
}

const addSplit = () => {
  if (Number(newExpense.sum)){
    addExpense(newExpense)
    changeSum()
    splitRow.value = false
  }


}

const update = (expense) => {
  const dbObject = () => doc(db, "expenses", props.expense.id);
  updateDoc(dbObject(), expense)
}

watch(newExpense, (value) => unsavedSum.value = props.expense.sum - Number(value.sum))
watch([splitRow, editSum], () => {
  unsavedSum.value = props.expense.sum
  newExpense.sum = ''
})
watch(() => props.expense.sum, () => unsavedSum.value = props.expense.sum)

</script>

<style scoped>
.remove{
  cursor: pointer;
  text-align: center;
}
.new{
  color: #999999;
}
.flex{
  display: flex;
  justify-content: space-between;
  gap: 20px
}
.input.sum{
    width: 60px;
}
</style>