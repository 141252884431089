<template>
  <div class="header">
  <div class="container">
    <div>Finance</div>
    <div class="menu">
      <router-link to="/">Expenses</router-link>
      <router-link to="/groups">Groups</router-link>
      <router-link to="/stat">Stat</router-link>
    </div>
    <slot/>
  </div>

  </div>
</template>
<script setup>

</script>

<style >
  .header{
    height: 40px;
    background: #efefef;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
  }

  .header .container{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  .menu{
    display: flex;
    gap:20px;

  }
  .menu > *{
    cursor: pointer;
  }
  .menu > *:hover{
    color: #666666;
  }
</style>