<template>
  <div class="wrapper">
    <div class="categories">
      <button
          v-for="group in groups"
          :class="['btn', {'active': group.id === activeGroup}]"
          @click="activeGroup = group.id"
          :key="group.id"><ColorBox :color="group.color"/>{{group.title}}</button>
    </div>
    <div class="scaner">
      <QrStream @decode="decode" />
    </div>
  </div>
</template>

<script setup>
import { QrStream } from 'vue3-qr-reader';
import {defineEmits, ref} from "vue";
import {useCollection} from "vuefire";
import {topGroupsQuery} from "@/groups";
import ColorBox from "@/components/ColorBox";

const emit = defineEmits(['decode'])
const groups = useCollection(topGroupsQuery, {ssrKey:'-'})
const activeGroup = ref('ncMJAqOycvZO9VZBTFfi')

const decode = (result) => {
  const findItem = (key) => {
    return data.reduce((result, item) => {
      return item.key === key?item.value:result
    }, null)
  }
  const data = result.split('&').map((p)=> {const vars = p.split('='); return {key: vars[0], value: vars[1]}})
  const qrdt = findItem('t').split('T')
  const response = {
    sum: findItem('s'),
    date: qrdt[0].slice(0,4) + '-' + qrdt[0].slice(-4, -2) + '-' + qrdt[0].slice(-2) + ' ' + qrdt[1].slice(0, 2)+ ':' + qrdt[1].slice(-2),
    group_id: activeGroup.value
  }
  emit('decode', response)
}
</script>

<style scoped>
.wrapper{
  position: fixed;
  left: 0;
  top: 0;
  padding: 15px;
  background: rgb(0 0 0 / 50%);
  z-index: 11;
}
.categories{

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
}
.categories .btn{
  font-size: 150%;
}
.scaner{
  display: block;

  z-index: 1;
  top: 50px;
  left: 0;

}
</style>