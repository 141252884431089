import { initializeApp } from 'firebase/app'
import {getFirestore, collection} from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyCOp7dAQ3O-JcwDLLTLC1PYbsW_7IHAr8I",
    authDomain: "finance-1e7a2.firebaseapp.com",
    projectId: "finance-1e7a2",
    storageBucket: "finance-1e7a2.appspot.com",
    messagingSenderId: "1005047800071",
    appId: "1:1005047800071:web:a2162adb62df604be925a2"
};

export const firebaseApp = initializeApp(firebaseConfig)

// used for the firestore refs
const db = getFirestore(firebaseApp)

import { query, orderBy, limit } from "firebase/firestore";


// here we can export reusable database references
export const expensesRef = collection(db, 'expenses')
export const queryExpenses = query(expensesRef, orderBy("date", "desc"), limit(10));