<template>
<div class="loading">{{loading}}</div>
</template>

<script setup>
import {computed, ref} from "vue";

let dots = ref(0)
setInterval(() => dots.value = dots.value>4?0:dots.value+1, 300)
const loading = computed(() => 'Loading' + '.'.repeat(dots.value))
</script>

<style scoped>
.loading{
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
}
</style>