<template>
  <QrScaner v-if="scan" @decode="add"></QrScaner>
  <div class="form">
    <Datepicker v-model="newExpense.date" locale='ru' utc="preserve" :text-input="true" :enable-time-picker="false"
                :auto-apply="true" format="dd.MM.yyyy" placeholder="Дата" />
    <GroupSelect v-model="newExpense.group_id" class="input"  @change="inputSum.focus()" autofocus/>
    <input v-model="newExpense.sum" type="text" class="input sum" placeholder="Сумма" ref="inputSum"/>
    <button @click="() => add(newExpense)" class="btn">
      <IconPlus/>
      Добавить
    </button>
  </div>
  <div class="scan-btn">
    <button class="btn" v-if="scan" @click="scan=false">
      <IconRemove :width="20"/>
    </button>
    <button class="btn" v-else @click="scan=true">
      <IconQr  :width="20"/>
    </button>
  </div>
</template>

<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import {reactive, ref} from "vue";
import {addExpense} from "@/expenses";
import QrScaner from "@/components/QrScaner";
import IconPlus from "@/components/icons/IconPlus";
import GroupSelect from "@/components/GroupSelect";
import IconRemove from "@/components/icons/IconRemove";
import IconQr from "@/components/icons/IconQr";


const defaultExpense = {
  date: new Date,
  group_id: 'ncMJAqOycvZO9VZBTFfi',
  sum: '',
}

let newExpense = reactive(Object.assign({}, defaultExpense))
const inputSum = ref(null)
const scan = ref(null)


const add = (expence) => {
  scan.value = false

  if (Number(expence.sum)){
    addExpense(expence).then(() => {
      newExpense.group_id = defaultExpense.group_id
      newExpense.sum = ''
    });
  }

}

</script>

<style >
.scan-btn{
  position: fixed;
  left: 25px;
  bottom: 25px;


}
.scan-btn .btn{
  height: 45px;
  width: 45px;
  border-radius: 100%;
}


.form{
  display: flex;
  flex-wrap: wrap;
  gap: 5px 10px;
  margin: 10px 0;
}
.form > *{
  flex: 1
}
.dp__main{
  display: inline-block;
  font-family: inherit;
  width: auto;
}
.dp__input_wrap{
  display: inline-block;
  width: auto;
}
.dp__input {
  background: transparent;
  border-radius: 0;
  border: 1px solid #a7a7a7;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  padding: 6px 25px;
  box-sizing: border-box;
  /*  width: 125px;*/

}
.dp__input_icons{
  padding: 6px;
}
</style>