import {addDoc, collection, doc, orderBy, where, query} from "firebase/firestore";
import {useFirestore} from "vuefire";


const db = useFirestore()
export const group = (group_id) => doc(db, 'groups/'+group_id)

export const groupsQuery = query(collection(db, 'groups'), orderBy("sort"))
export const topGroupsQuery = query(collection(db, 'groups'), where("top", "==", 1), orderBy("sort"))
export const groupsRef = collection(db, 'groups')

export const addGroup = (data) => {
    return addDoc(groupsRef, data);
}