<template>
  <div v-if="allow">
    <AppHeader>
    </AppHeader>
    <div class="container">
      <router-view/>
    </div>
  </div>
  <LoadingBlock v-else/>

</template>

<script setup>

import {onMounted, watch, computed} from "vue";

import { useFirebaseAuth } from 'vuefire'
import { useCurrentUser } from 'vuefire'
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth'
import AppHeader from "@/components/AppHeader";
import LoadingBlock from "@/components/LoadingBlock";


const provider = new GoogleAuthProvider();
const auth = useFirebaseAuth()
const curruser = useCurrentUser()
const allow = computed(() => curruser.value && ['akWmswS9m1QVFcparebEcDbigg52', 'CClj4kejyUPC9Ku7YHkEUTiXMGY2'].includes(curruser.value.uid))





onMounted(() => {
  watch(curruser, (user) => {
    if (!user) {
      signInWithPopup(auth, provider)
          .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            console.log(credential,result)
          }).catch((e) => {console.log(e)})
    }else{
      console.log(user.photoURL)
    }
  })
})



</script>
<style>
body{
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: sans-serif;
  font-size: 14px;
  color: #333333;
}
.container{
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
}
@media (min-width: 769px) {
  .container{
    width: 768px;
  }
}



.input{
  padding: 6px;
  border: 1px solid #a7a7a7;
}


.btn{
  border: 1px solid #a7a7a7;
  background: #cecece;
  padding: 6px 12px;
  cursor: pointer;
  transition: background .2s ease-in-out;
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn.active, .btn:active{
  background: #8c8c8c;
  border-top-color: #000;
  border-right-color: #000;
}

.btn:hover{
  background: #9f9f9f;
}

table.table{
  border-collapse: collapse;
  width: 100%;
}
table.table td, table.table th{
  border: 1px solid #dddddd;
  padding: 4px 8px;
}
</style>
